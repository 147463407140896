/* html {
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  background: var(--active-color);
  transition: background 0.4s ease;
} */

button {
  background: none;
  border: none;
  cursor: pointer;
}

input[type='range'] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  /* border-radius: 8px; */
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}

.audio-player {
  width: 100%;
  /* padding: 24px; */
  /* padding-bottom: 12px; */
  /* padding-top: 12px; */
  /* border-radius: 20px; */
  /* box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2); */
  /* margin: auto; */
  /* color: var(--white); */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}

.audio-player--isMinimised {
  display: none;
  width: unset;
  padding: 0;
  align-items: unset;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  /* padding-right: 30px; */
}

.audio-scroll {
  /* min-width: 150px; */
  max-width: 200px;
  height: 29px;
}

@media (min-width: 425px) {
  .audio-scroll {
    height: 29px;
  }
}

@media (min-width: 320px) {
  .audio-player--isMinimised {
    display: flex;
  }
}

.artwork {
  /* border-radius: 120px; */
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  font-weight: 700;
  margin-bottom: 4px;
}

.artist {
  font-weight: 300;
  margin-top: 0;
}

/* .audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
}

.audio-controls .prev svg,
.audio-controls .next svg {
  width: 35px;
  height: 35px;
} */

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 15px;
  width: 20px;
}

@media (min-width: 425px) {
  .audio-controls .play svg,
  .audio-controls .pause svg {
    height: 20px;
    width: 25px;
  }
}

@media (min-width: 768px) {
  .audio-controls .play svg,
  .audio-controls .pause svg {
    height: 25px;
    width: 30px;
  }
}

.audio-controls path {
  fill: #d2694a;
}

.color-backdrop {
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
    no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.color-backdrop.playing {
  -webkit-animation: colorChange 20s alternate infinite;
  animation: colorChange 20s alternate infinite;
}

@-webkit-keyframes colorChange {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

@keyframes colorChange {
  from {
    -webkit-filter: hue-rotate(0deg);
    filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(360deg);
    filter: hue-rotate(360deg);
  }
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #d2694a;
  height: 12px;
  width: 12px;
  border-radius: 10px;
  background: #d2694a;
  cursor: pointer;
  margin-top: -1px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  /* box-shadow: 1px 1px 1px red, 0px 0px 1px red; Add cool effects to your sliders! */
}

@media (min-width: 425px) {
  input[type='range']::-webkit-slider-thumb {
    height: 14px;
    width: 14px;
  }
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  border: 1px solid #d2694a;
  height: 12px;
  width: 12px;
  border-radius: 10px;
  background: #d2694a;
  cursor: pointer;
}

@media (min-width: 425px) {
  input[type='range']::-moz-range-thumb {
    height: 14px;
    width: 14px;
  }
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  /* box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; */
  border: 1px solid #d2694a;
  height: 12px;
  width: 12px;
  border-radius: 10px;
  background: #d2694a;
  cursor: pointer;
}

@media (min-width: 425px) {
  input[type='range']::-ms-thumb {
    height: 14px;
    width: 14px;
  }
}
