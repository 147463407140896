.form {
  display: flex;
  flex-direction: column;
  /* max-width: 80%; */
  margin: auto;
  align-items: center;
}

.form--title {
  /* margin-bottom: 28px; */
  line-height: 28.14px;
  letter-spacing: 5.25px;
}

.form-message--sending {
  font-size: 18px;
  padding-bottom: 20px;
  color: white !important;
  font-family: 'poppins';
}

.form-message--error {
  padding-bottom: 20px;
  font-size: 18px;
  color: white !important;
  font-family: 'poppins';
}

.form-message--success {
  padding-bottom: 20px;
  font-size: 18px;
  color: white !important;
  font-family: 'poppins';
}

.form-message--ok {
  padding-bottom: 20px;
  font-size: 18px;
  color: white;
  font-family: 'poppins';
}

.form-input-field {
  height: 38px;

  font-size: 18px;
  padding: 15px;
  margin-bottom: 32px;
  border-radius: 3px;
  border: solid 1px #edd8e9;
  display: block;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (min-width: 768px) {
  .form-input-field {
    width: 100%;
  }
}

@media (min-width: 2560px) {
  .form-input-field {
    height: 68px;
    font-size: 24px;
  }
}

.form-submit-button {
  margin-bottom: 24px;
  position: relative;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  width: 60%;
  /* height: 4.25rem; */
  font-size: 18px;
  font-weight: 600;
  /* line-height: 1.08; */
  /* letter-spacing: 0.203125rem; */
  text-align: center;
  color: #edd8e9;
  border-radius: 20px;
  border: 0.0625rem solid #d2694a;
  background-color: #d2694a;
  display: inline-block;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
}

@media (min-width: 1024px) {
  .form-submit-button {
    font-size: 20px;
    /* width: 50%; */
  }
}

@media (min-width: 2560px) {
  .form-submit-button {
    font-size: 28px;
  }
}

.form-submit-button:hover {
  /* background-color: darkgreen; */
  color: white;
}

.form-submit-button:after {
  border-radius: 1.6875rem;
  content: '';
  background: #edd8e9;
  color: #d2694a;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.form-submit-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.form--error {
  display: none;
}
