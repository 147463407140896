html,
body {
  margin: 0;
  font-family: 'Advent Pro', 'Poppins light', 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #edd8e9;
  height: 100%;
  overflow: hidden;
}

button {
  font-family: 'Advent Pro', 'Poppins light', 'Arial';
}

input {
  font-family: 'Advent Pro', 'Poppins light', 'Arial';
}
