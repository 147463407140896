.share-modal {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  border-radius: 24px;
  color: #d2694a;
  background-color: #edd8e9;
  margin-left: 8px;
  margin-right: 8px;
}
.share-modal > .header {
  font-size: 18px;
  text-align: center;
  padding: 24px;
  margin-bottom: 18px;
}
.share-modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.share-modal > .actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 18px;
}
.share-modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  /* border-radius: 18px; */
  /* border: 1px solid #cfcece; */
}

.actions--item {
  margin-left: 2px;
  margin-right: 2px;
}

.popup-content {
  width: 90vh;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
