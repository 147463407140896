.welcome {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 24px;
  color: #d2694a;
  background-color: #edd8e9;
  margin-left: 8px;
  margin-right: 8px;
  opacity: 1;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 2500ms;
  animation-duration: 2500ms;
}

.welcome:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 33px solid transparent;
  border-bottom-color: #edd8e9;
  border-top: 0;
  border-left: 0;
  margin-left: -16.5px;
  margin-top: -33px;
  opacity: 1;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.welcome--close {
  position: absolute;
  right: 14px;
  top: 10px;
  color: #d2694a;
  cursor: pointer;
}

.welcome p {
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  -webkit-margin-before: 10px;
  margin-block-start: 10px;
}

.welcome--title {
  font-size: 16px;
  font-weight: 600;
}

.welcome--text {
  font-size: 14px;
}

@media (min-width: 768px) {
  .welcome {
    left: 25%;
    right: 25%;
  }
  .welcome--title {
    font-size: 22px;
  }
  .welcome--text {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .welcome--title {
    font-size: 26px;
  }
  .welcome--text {
    font-size: 22px;
  }
}
