.cookie-consent--overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #edd8e9c7;
  border-radius: 22px;
}

.cookie-consent--button {
  box-shadow: none;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 15px;
  font-size: 18px;
  font-weight: 600;
  /* line-height: 1.08; */
  /* letter-spacing: 0.203125rem; */
  text-align: center;
  color: #d2694a;
  border-radius: 20px;
  border: 0.0625rem solid #edd8e9;
  background-color: #edd8e9;
  display: inline-block;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
}

@media (min-width: 768px) {
  .cookie-consent--button {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .cookie-consent--button {
    font-size: 28px;
  }
}

.cookie-consent--container {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  bottom: 0px;
  align-items: baseline;
  background: #d2694a;
  color: white;
  display: block;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.cookie-consent--content {
  font-size: 18px;
  /* flex: 1 0 300px; */
  margin: 15px;
}

@media (min-width: 768px) {
  .cookie-consent--content {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .cookie-consent--content {
    font-size: 28px;
  }
}

/* 
@media (min-width: 1024px) {
  .cookie-consent--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
} */
