.form-container {
  display: flex;
  flex-direction: column;
  max-width: 80vw;
  align-content: center;
  justify-content: space-between;
  justify-items: center;
  flex-wrap: nowrap;
  margin: auto;
  /* height: 100%; */
  min-height: 80vh;
}

@media (min-width: 768px) {
  .form-container {
    max-width: 50%;
  }
}
