.modal-content-wrapper {
  width: 100%;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-content {
  position: relative;
  margin: 24px;
  box-sizing: border-box;
  /* min-height: 50px; */
  /* min-width: 50px; */
  /* width: 80%; */
  /* box-shadow: 0 3px 6px indianred, 0 3px 6px indianred; */
  /* background-color: #edd8e9; */
  border-radius: 22px;
}

@media (min-width: 768px) {
  .modal-content {
    width: 60%;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: #edd8e9; */

  text-align: center;
  -webkit-backdrop-filter: blur;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 2000ms;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-backdrop .modal-content {
  height: 100%;
  transform: translateY(100px);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}
.modal-backdrop.active {
  transition-duration: 250ms;
  transition-delay: 0ms;
  opacity: 1;
}
.modal-backdrop.active .modal-content {
  transform: translateY(0);
  opacity: 1;
  transition-delay: 150ms;
  transition-duration: 2500ms;
  /* width: 100%; */
}

@media (min-width: 375px) {
  .modal-backdrop.active .modal-content {
    width: 90%;
  }
}

@media (min-width: 768px) {
  .modal-backdrop.active .modal-content {
    width: 60%;
  }
}
