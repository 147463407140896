/* .mediaCard-text::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
/* .mediaCard-text::-webkit-scrollbar-track {
  background: #edd8e9;
} */

/* Handle */
/* .mediaCard-text::-webkit-scrollbar-thumb {
  background: #d2694a;
} */

/* Handle on hover */
/* .mediaCard-text::-webkit-scrollbar-thumb:hover {
  background: #edd8e9;
} */

.mediaCard {
  overflow-y: hidden;
  max-height: 90vh;
  max-width: 345px;
  color: #d2694a;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 10px;
  padding: 30px;
  visibility: hidden;
  -webkit-animation: 2s fadeIn;
  animation: 2s fadeIn;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.carousel .control-dots {
  position: relative !important;
}

.mediaCard--isMinimised {
  /* max-width: 100%; */
}

.mediaCard-image {
  width: 100%;
  border-radius: 0 95px;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

.--isMinimised {
  display: none;
}

.mediaCard-content--item {
  min-height: 20vh;
}

.mediaCard-text {
  overflow-y: scroll;
  color: #d2694a;
  font-size: 14px;
  text-align: left;
  min-height: 30vh;
  max-height: 30vh;
  margin-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.mediaCard-content {
  /* display: flex;
  flex-direction: column;
  flex-wrap: nowrap; */
  /* width: 330px; */
  cursor: pointer;
  /* padding-left: 26px; */
  /* padding-right: 26px; */
}

.mediaCard-content--carousel {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.mediaCard-songtitle {
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.mediaCard-city {
  font-size: 12px;
  -webkit-margin-after: 0px;
  margin-block-end: 0px;
  -webkit-margin-before: 10px;
  margin-block-start: 10px;
}

@media (min-width: 425px) {
  .mediaCard-songtitle {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .mediaCard-songtitle {
    font-size: 22px;
  }
  .mediaCard-text {
    font-size: 18px;
    min-height: 40vh;
    max-height: 40vh;
    /* min-height: 60vh; */
  }
}

.mediaCard-cta-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 12px;
}

.mediaCard--isMinimised .mediaCard-cta-wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}

.mediaCard-cta a:link {
  color: inherit;
}

.mediaCard-cta a:hover {
  color: inherit;
}

.mediaCard-cta a:active {
  color: inherit;
}

.mediaCard-cta a:visited {
  color: inherit;
}

.mediaCard-controls {
  display: flex;
  align-items: center;
}

.mediaCard-cta {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5px;
  /* padding-bottom: 12px; */
  cursor: pointer;
}

.mediaCard--isMinimised .mediaCard-cta {
  min-width: 80px;
  padding: 0;
}

.mediaCard-cta svg {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 425px) {
  .mediaCard-cta svg {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .mediaCard-cta svg {
    font-size: 30px;
  }
}

.mediaCard-cta button {
  font-size: 20px;
  min-width: 115px;
  min-height: 46px;
  color: #edd8e9;
  border: 1px solid #edd8e9;
  background-color: #d2694a;
}

.mediaCard-card {
  /* display: flex;
  flex-direction: column; */
  border-radius: 18px;
  background-color: #edd8e9;
  overflow-y: scroll;
  max-height: 90vh;
  align-items: center;
}

@media (min-width: 768px) {
  .mediaCard-cta svg {
    font-size: 30px;
  }
}

.mediaCard--isMinimised .mediaCard-card {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px;
}

/* @media (min-width: 768px) {
  .mediaCard--isMinimised .mediaCard-card {
    padding: unset;
  }
} */

.mediaCard-minimise {
  position: absolute;
  right: 34px;
  top: 27px;
  width: 42px;
  height: 32px;
  color: #d2694a;
  z-index: 99999;
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
}

.mediaCard-close {
  position: absolute;
  right: 32px;
  top: 42px;
  width: 42px;
  height: 32px;
  z-index: 99999;
  cursor: pointer;
}
.mediaCard-minimise,
.mediaCard-close:hover {
  opacity: 1;
}
.mediaCard-close:before,
.mediaCard-close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: black;
}
.mediaCard-close:before {
  transform: rotate(45deg);
}
.mediaCard-close:after {
  transform: rotate(-45deg);
}

.mediaCard-content-link {
  float: right;
  color: #d2694a;
  font-weight: 600;
  font-size: 16px;
}
